import './style/Login.css';
import google from '../media/google.png';

const Login = ({ login, isCustomer, isAuthenticated }) => {
    return (
        <div id="login">
            <div id="login-wrapper">
                <div id="login-content-wrapper">
                    <div id="login-header-wrapper">
                        <p id="login-title">Login</p>
                        <p id="login-description">Inventory analytics and exploration hub.</p>
                    </div>
                    <div id="login-details-wrapper">
                        <button id="login-google-button" onClick={() => login({
                            authorizationParams: {
                                scope: "openid profile email offline_access"
                            }
                        })}>
                            <img src={google} alt="Google's 'G' Logo"/>
                            <span>Sign in with Google</span>
                            <img src={google} alt="Google's 'G' Logo"/>
                        </button>
                        <p id="login-details">Access your dashboard using your organization email address.</p>
                    </div>
                    <div id="login-footer-wrapper" className={(isCustomer === false && isAuthenticated) ? "cta" : ""}>
                        <p id="login-redirect">Not a customer? Check out <a href="https://trymaterialize.com">what we do here</a>.</p>
                    </div>
                </div>
                <a href='/privacy' id="login-privacy-anchor">Privacy Policy</a>
            </div>
        </div>
    );
};

export default Login;