import Product from './Product';
import './style/Store.css';
import React, { useEffect, useState } from 'react';

const Store = () => {
    const [isProductPage, setIsProductPage] = useState(true);

    return (
        <div id="store">
            { isProductPage ? (
                <Product></Product>
            ) : (
                <>  </>
            )}
        </div>
    );
};

export default Store;