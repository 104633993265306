import './style/Product.css';
import React, { useEffect, useState } from 'react';

import sampleImageOne from '../media/nvidia.png'
import sampleImageTwo from '../media/nvidia-2.jpg'
import specification from '../media/icon_specification.png'
import expand from '../media/icon_expand.png'
import leftPrimary from '../media/icon_left_primary.png'
import leftSecondary from '../media/icon_left_secondary.png'
import rightPrimary from '../media/icon_right_primary.png'
import rightSecondary from '../media/icon_right_secondary.png'
import box from '../media/icon_box.png'
import boxGreen from '../media/icon_box_green.png'
import sampleProduct from './sample_product.json'

const Product = () => {
    const [purchaseType, setPurchaseType] = useState(0);
    const [costInCents, setCostInCents] = useState(23274); // 232.74
    const [quantity, setQuantity] = useState(1);
    const [offer, setOffer] = useState('200.00');
    const [totalInCents, setTotalInCents] = useState(23274); // 232.74
    const [description, setDescription] = useState(sampleProduct.description.substring(0, 300) + "...");
    const [images, setImages] = useState([sampleImageOne, sampleImageTwo]);
    const [imageIndex, setImageIndex] = useState(0);

    useEffect(() => {
        if (purchaseType === 0) {
            setTotalInCents(23274 * quantity);
        } else {
            setTotalInCents(Number(offer) * quantity * 100);
        }
    }, [quantity, offer, purchaseType]);

    const handleQuantityInputChange = (e) => {
        const value = e.target.value;
        // Use a regex to ensure all input is numeric
        if (/^\d+$/.test(value)) {
            setQuantity(value);
        } else if (value === '') {
            // Allow empty input to reset the field
            setQuantity('');
        }
    };

    const handleQuantityBlur = (e) => {
        const value = e.target.value;
        // Ensure input is at least 1 when the input loses focus
        if (value === '' || parseInt(value, 10) < 1) {
            setQuantity(1);
        }
    };

    const handleOfferInputChange = (e) => {
        const value = e.target.value;
        // Regular expression to ensure the input is numeric and can include decimals with at most two digits after the decimal point
        if (/^\d*\.?\d{0,2}$/.test(value)) {
            setOffer(value);
        } else if (value === '') {
            // Allow empty input to reset the field
            setOffer('');
        }
    };
    
    const handleOfferBlur = (e) => {
        const value = e.target.value;
        // Ensure input is at least 1 when the input loses focus
        if (value === '' || parseFloat(value) < 1) {
            setOffer(1);
        } else {
            // Set the offer to the valid numeric value with up to two decimal places
            setOffer(parseFloat(value).toFixed(2));
        }
    };

    return (
        <div id="product">
            <div id="product-details-wrapper">
                <h3 id="product-manufacturer">NVIDIA</h3>
                <h1 id="product-number">900-83668-0000-000</h1>
                <div id="product-tags-wrapper">
                    <div className="product-tag green">
                        <img id="product-box-green-icon" src={boxGreen}></img>
                        <p className="product-tag-text">New In-Box</p>
                    </div>
                    <div className="product-tag blue">
                        <img id="product-box-icon" src={box}></img>
                        <p className="product-tag-text">Ships Immediately</p>
                    </div>
                </div>
                <div id="product-description-wrapper">
                    <p>
                        {description}&nbsp;
                        <a onClick={() => setDescription(sampleProduct.description.length === description.length ? sampleProduct.description.substring(0, 300) + "..." : sampleProduct.description)}>{sampleProduct.description.length === description.length ? "show less." : "show more."}</a>
                    </p>
                </div>
                <div id="product-footer-wrapper">
                    <button id="product-specification-button">
                        <img id="product-specification-button-icon" src={specification}></img>
                        <p id="product-specification-button-text">View Specification</p>
                    </button>
                    <div id="product-price-wrapper">
                        <p id="product-price">$232.74</p>
                        <p id="product-quantity">128 in Stock</p>
                    </div>
                </div>
            </div>
            <div id="product-interaction-wrapper">
                <div id="product-image-wrapper">
                    <img id="product-image" src={images[imageIndex]} alt="No Image" />
                    <div id="product-image-controls-overlay">
                        <button id="product-image-button-expand">
                            <img id="product-expand-button-icon" src={expand}></img>
                        </button>
                        <div id="product-image-button-navigate">
                            <div id="product-image-button-navigate-wrapper">
                                <button id="product-image-button-left" onClick={() => {setImageIndex(0 !== imageIndex ? imageIndex - 1 : imageIndex)}}>
                                    <img id="product-left-button-icon" src={imageIndex === 0 ? leftSecondary : leftPrimary}></img>
                                </button>
                                <button id="product-image-button-right" onClick={() => {setImageIndex(images.length - 1 !== imageIndex ? imageIndex + 1 : imageIndex)}}>
                                    <img id="product-right-button-icon" src={imageIndex === images.length - 1 ? rightSecondary : rightPrimary}></img>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="product-purchase-wrapper">
                    <div id="product-purchase-type-wrapper">
                        <button id="product-purchase-button-buy" className={purchaseType === 0 ? "active" : ""} onClick={() => { setPurchaseType(0)}}>Buy</button>
                        <button id="product-purchase-button-offer" className={purchaseType === 1 ? "active" : ""} onClick={() => { setPurchaseType(1)}}>Offer</button>
                    </div>
                    <div id="product-purchase-quantity-wrapper">
                        <p id="product-purchase-quantity-text">Quantity</p>
                        <div id="product-purchase-quantity-input-wrapper">
                            <div id="product-purchase-quantity-x-wrapper">
                                <p id="product-purchase-quantity-x">x</p>
                            </div>
                            <input id="product-purchase-quantity-input" type="text" value={quantity} onChange={handleQuantityInputChange} onBlur={handleQuantityBlur} />
                        </div>
                    </div>
                    {purchaseType === 1 && <div id="product-purchase-offer-wrapper">
                        <p id="product-purchase-offer-text">Offer per Unit</p>
                        <div id="product-purchase-offer-input-wrapper">
                            <div id="product-purchase-offer-x-wrapper">
                                <p id="product-purchase-offer-x">$</p>
                            </div>
                            <input id="product-purchase-offer-input" type="text" value={offer} onChange={handleOfferInputChange} onBlur={handleOfferBlur} />
                        </div>
                    </div>}
                    <button id="product-purchase-button">
                        <p>{purchaseType === 0 ? "Add to Cart" : "Make Offer"}</p>
                        <p id="product-purchase-subtotal">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol'}).format(totalInCents / 100)}</p>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Product;