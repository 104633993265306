
import React, { useEffect, useState, useRef } from 'react';
import Retool from 'react-retool';

const FullViewIframe = ({ url, handleLogout }) => {
    const iframeRef = useRef(null);
    const containerRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const logMessage = (event) => {
          console.log("Log triggered");

          if (event.origin !== "https://hub.trymaterialize.com") {
            // Check the origin of the message to ensure it's from your Retool instance
            return;
          }

          if (event.data.eventType === 'retoolData') {
            if (event.data.payload === 'logout') {
              handleLogout();
            }
          }
        }

        window.addEventListener("message", logMessage);

        return () => window.removeEventListener("message", logMessage);
    }, [])

      
    return (
      <div ref={containerRef} style={{ width: '100%', height: '100%', overflow: 'hidden', zIndex: 2 }}>
          {url && (
            //   <iframe
            //       ref={iframeRef}
            //       src={url}
            //       width={dimensions.width}
            //       height={dimensions.height + 60}
            //       frameBorder="0"
            //       allowFullScreen
            //       allowTransparency="true"
            //   ></iframe>
            <Retool url={url} />
          )}
        </div>
    );
};

export default FullViewIframe;